import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class DHLExpressSettingsProvider extends HttpRequest {
  getLastSetting () {
    this.setHeader(getAuthToken())
    return this.get('/dhl-express-settings')
  }

  updateSetting (payload) {
    this.setHeader(getAuthToken())

    return this.post('/dhl-express-settings', payload)
  }
}

export default DHLExpressSettingsProvider
