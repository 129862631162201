<template>
  <v-dialog
    v-model="active"
    max-width="500px"
    class="rounded-lg">
    <v-card class="rounded-lg">
      <v-card-title>
        <span
          class="py-1"
          style="font-size: 1.75rem; font-weight: 500;">
          Pick-up Warehouse Form
        </span>
      </v-card-title>
      <v-card-text>
        <validation-observer
          ref="modal-form"
          tag="form"
          @submit.prevent="onSubmit()">
          <v-row>
            <v-col cols="12">
              <WarehouseSelect
                v-model="formData.warehouse"
                :items="warehouses"
                item-text="name"
                item-value="id"
                :menu-props="{ offsetY: true }"
                label="Warehouse"
                outlined
                return-object
                @select="onSelectWarehouse($event)" />
            </v-col>
            <v-col cols="12">
              <gw-autocomplete
                v-model="formData.mode"
                :items="modes"
                label="Mode"
                item-text="text"
                item-value="value"
                :menu-props="{ offsetY: true }"
                color="secondary"
                dense
                outlined
                hide-details />
            </v-col>
            <v-col cols="12">
              <gw-text-field
                v-model="formData.closeTime"
                label="Close Time"
                type="time"
                color="secondary"
                dense
                outlined
                hide-details />
            </v-col>
            <v-col cols="12">
              <gw-text-field
                v-model="formData.pickUpTime"
                label="Pick-up Time"
                type="time"
                color="secondary"
                dense
                outlined
                hide-details />
            </v-col>
            <v-col cols="12">
              <gw-text-field
                v-model="formData.location"
                label="Location"
                :rules="{
                  required: true
                }"
                color="secondary"
                dense
                outlined
                hide-details />
            </v-col>
            <v-col cols="12">
              <v-divider />
            </v-col>
            <v-col cols="12">
              <gw-text-field
                v-model="formData.postalCode"
                label="Postal Code"
                :rules="{
                  required: true
                }"
                color="secondary"
                dense
                outlined
                hide-details />
            </v-col>
            <v-col cols="12">
              <gw-text-field
                v-model="formData.cityName"
                label="City Name"
                :rules="{
                  required: true
                }"
                color="secondary"
                dense
                outlined
                hide-details />
            </v-col>
            <v-col cols="12">
              <gw-text-field
                v-model="formData.countryCode"
                label="Country Code"
                :rules="{
                  required: true
                }"
                color="secondary"
                dense
                outlined
                hide-details />
            </v-col>
            <v-col cols="12">
              <gw-textarea
                v-model="formData.addressLine1"
                label="Address Line 1"
                :rules="{
                  required: true
                }"
                color="secondary"
                dense
                outlined
                hide-details />
            </v-col>
            <v-col cols="12">
              <gw-textarea
                v-model="formData.addressLine2"
                label="Address Line 2"
                :rules="{
                  required: true
                }"
                color="secondary"
                dense
                outlined
                hide-details />
            </v-col>
            <v-col cols="12">
              <gw-textarea
                v-model="formData.addressLine3"
                label="Address Line 3"
                :rules="{
                  required: true
                }"
                color="secondary"
                dense
                outlined
                hide-details />
            </v-col>
            <v-col cols="12">
              <gw-text-field
                v-model="formData.companyName"
                label="Company Name"
                :rules="{
                  required: true
                }"
                color="secondary"
                dense
                outlined
                hide-details
                disabled />
            </v-col>
            <v-col cols="12">
              <gw-text-field
                v-model="formData.fullName"
                label="Full Name"
                :rules="{
                  required: true
                }"
                color="secondary"
                dense
                outlined
                hide-details />
            </v-col>
            <v-col cols="12">
              <gw-text-field
                v-model="formData.phone"
                label="Phone"
                :rules="{
                  required: true
                }"
                color="secondary"
                dense
                outlined
                hide-details />
            </v-col>
            <v-col cols="12">
              <gw-text-field
                v-model="formData.email"
                label="Email"
                :rules="{
                  required: true
                }"
                color="secondary"
                dense
                outlined
                hide-details />
            </v-col>
            <v-col
              cols="12"
              class="d-flex justify-end align-center"
              style="gap: 0.5rem;">
              <v-btn
                color="error"
                text
                :disabled="loading"
                @click="active = false">
                ปิด
              </v-btn>
              <v-btn
                type="submit"
                color="success"
                text
                :disabled="loading">
                ยืนยัน
              </v-btn>
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import WarehouseProvider from '@/resources/WarehouseProvider'
import WarehouseSelect from '@/components/WarehouseSelect.vue'

const WarehouseService = new WarehouseProvider()

export default {
  components: {
    WarehouseSelect
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      loading: true,
      formData: {
        warehouse: null,
        mode: 'daily',
        closeTime: '22:00',
        pickUpTime: '15:00',
        location: '',
        postalCode: '',
        cityName: '',
        countryCode: 'TH',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        phone: '',
        companyName: 'GENTLEWOMAN CO., LTD.',
        fullName: '',
        email: ''
      },
      modes: [
        {
          text: 'Daily',
          value: 'daily'
        },
        {
          text: 'Schedule',
          value: 'schedule'
        }
      ],
      warehouses: [],
      textBoxRules: [
        (v) => !!v || 'This field is required'
      ]
    }
  },
  computed: {
    active: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    active (val) {
      if (val) {
        this.formData = {
          warehouse: null,
          mode: 'daily',
          closeTime: '22:00',
          pickUpTime: '15:00',
          location: '',
          postalCode: '',
          cityName: '',
          countryCode: 'TH',
          addressLine1: '',
          addressLine2: '',
          addressLine3: '',
          phone: '',
          companyName: 'GENTLEWOMAN CO., LTD.',
          fullName: '',
          email: ''
        }
      }
    },
    data (val) {
      if (val) {
        this.formData = val
      }
    }
  },
  mounted () {
    this.getWarehouses()
  },
  methods: {
    onSelectWarehouse (val) {
      if (val) {
        this.formData.location = val.name
        this.formData.postalCode = val.postcode
        this.formData.cityName = val.stateProvince
        this.formData.countryCode = 'TH'
        this.formData.addressLine1 = val.address
        this.formData.addressLine2 = val.address2
        this.formData.addressLine3 = `${val.subDistrict} ${val.district} ${val.stateProvince} ${val.postcode}`
      }
    },
    async getWarehouses () {
      try {
        this.loading = true

        const { data } = await WarehouseService.getWarehouse()

        this.warehouses = data
      } catch (error) {
        console.error('getWarehouses', error)
        this.store.dispatch('Components/setSnackbar', {
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async onSubmit () {
      const isValid = await this.$refs['modal-form'].validate()

      if (isValid) {
        this.$emit('submit', this.formData)
        this.active = false
      }
    }
  }
}
</script>

<style scoped>

</style>
